import React from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { PieChart } from '@/components/chart/pieChart'
import * as API from '@/api/request'
import _ from 'lodash'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { timeRanges } from '@/utils/time'

interface Props {
  data?: Array<any>
}

const lineCode = searchParams().lineCode
let content = <Loading />
const handleChartData = (data: any) => {
  return [
    { name: '稼动时长', value: _.round(data.usedHours, 2) },
    { name: '待机时长', value: _.round(data.idleHours, 2) },
  ]
}

const Equipment = (props: Props) => {
  // 设备
  const { data, error, loading } = useRequest(
    () => {
      return Promise.all([
        API.Equipment.getTodayUtilizationRate(lineCode),
        API.Equipment.getTodayElectricityValues(lineCode, { ...timeRanges.today() }),
        API.Equipment.getUtilizationHours(lineCode, { ...timeRanges.today() }),
      ])
    },
    { pollingInterval: 3 * 1000 }
  )

  if (loading) return content
  if (error) {
    content = <Error />
    return content
  }

  content = <React.Fragment>
    <LineLayout justify="start" padding={[20, 20, 0, 30]}>
      <DigitalFlop count={_.round(data[0] * 100, 1)} suffix="%" title="稼动率" minWidth={180} type='count' />
      <DigitalFlop count={data[1].quantityInKWH} suffix="度" title="能耗" color="#efad1f" type='count' />
    </LineLayout>
    <PieChart id="equipment-thumbnail" type='time' margin="40px 0 0 0" title="稼动时长" color={['#05d380', '#eee']} data={handleChartData(data[2])} />
  </React.Fragment>

  return content
}
export { Equipment }
