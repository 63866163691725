
import React, { Fragment, useState } from 'react'
import './index.scss'
import { Export } from '@/api/request'
import Icon from '../../components/icon/icon'
import { requestBaseUrl } from '@/api/initEnv'
import { useMount } from 'ahooks'
import { searchParams } from '@/utils'

//匹配页面和导出接口
const pathnameToExportAPI = {
    '/': '/Export/ExportSummaryReport',
    '/line': '/Export/ExportSummaryReport',
    '/line/production': '/Export/ExportProductionReport',
    '/line/quality': '/Export/ExportQualityReport',
    '/line/equipment': '/Export/ExportEquipmentUtilizationReport',
    '/line/environment': '/Export/ExportEnvironmentReport',
    '/line/andon': '/Export/ExportAndonReport',
    '/workShop': '/Export/ExportSummaryReport',
    '/workShop/production': '/Export/ExportSummaryReport',
    '/workShop/quality': '/Export/ExportProductionReport',
    '/workShop/equipment': '/Export/ExportEquipmentReport',
    '/workShop/environment': '/Export/ExportEnvironmentReport',
    '/workShop/andon': '/Export/ExportAndonReport',
    '/iot': '/Export/ExportLogisticsReport',
    '/iotUpgradeSecond': '/Export/ExportLogisticsReport',
}
export const ExportReportButton = () => {
    const [showExportButton, setShowExportButton] = useState(false)
    const [exportUrl, setExportUrl] = useState('')
    useMount(() => {
        const lineCode = searchParams().lineCode
        const pathname = window.location.pathname
        for (let key in pathnameToExportAPI) {
            setShowExportButton(pathnameToExportAPI[pathname] !== undefined)
        }
        setExportUrl(requestBaseUrl + pathnameToExportAPI[pathname] + '?lineCode=' + lineCode)
    })

    return <Fragment>
        {
            showExportButton ?
                <a href={exportUrl} title='点击导出数据' className='exportReportButton-container'>
                    {/* 方案1 */}
                    {/* <Icon url={require('../../assets/images/export.png')} size={2.3} />
                    <span>导出</span> */}
                    <button type="button">
                        <span>导出报表</span>
                    </button>
                </a> : null
        }

    </Fragment>

}