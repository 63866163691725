import React from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import './index.scss'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { store } from '@/store'
//产线编号
const lineCode = searchParams().lineCode || null

const Home = (props: any) => {


  const { loading, data, error } = useRequest(() => {
    return Promise.all([
      Call.hasCallButtonSystem(lineCode),
      Quality.getHasVisionInspectionSystem(),
    ])
  })
  if (loading) return <Loading />
  console.log(data[1]);
  // if (error) return <Error />
  const ovenModuleWidth = data ? 1355 : 1920
  let thumbnails: Array<any> = [
    { title: '生产', jumpTo: '/workShop/production', children: <Thumbnail.Production />, border: <Border1 />, width: 420, icon: require('../../../assets/images/production.png') },
    { title: '质量', jumpTo: '/workShop/quality', children: data[1] ? <Thumbnail.Quality /> : <Thumbnail.QualityNoVision />, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
    { title: '设备', jumpTo: '/workShop/equipment', children: <Thumbnail.Equipment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/equipment.png') },
    { title: '环境', jumpTo: '/workShop/environment', children: <Thumbnail.Environment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/th.png') },
  ]
  const pageName = `ZS-TEK_${store.getState().projectInfo.shortCode}智能化车间`
  return (
    <Layout name={pageName}>
      {/* 产量、质量、设备、环境 四个模块 */}
      <LineLayout margin={20} height={480}>
        {thumbnails
          .map((item) => (
            <Card title={item.title} showMoreBtn={true} jumpTo={item.jumpTo} border={item.border} width={item.width} children={item.children} icon={item.icon} />
          ))}
      </LineLayout>
      {/* 警讯、产线入口*/}
      <LineLayout height={430} justify='between' margin={[40, 20, 20, 20]}>
        {
          data ?
            <Card
              title='呼叫'
              jumpTo='/workShop/andon'
              showMoreBtn={true}
              border={<Border1 />}
              width={420}
              children={<Thumbnail.Andon />}
              icon={require('../../../assets/images/th.png')} /> : null
        }
        <Card
          title='全部产线'
          children={<Thumbnail.LineEntry />}
          border={<Border1 />}
          showMoreBtn={true}
          width={ovenModuleWidth}
          icon={require('../../../assets/images/th.png')} />
        <Qrcode />
      </LineLayout>
    </Layout >
  )
}
export { Home }
